import {
    Builder_,
    Checkbox_,
    Dates_,
    Dropdown_,
    Main_,
    Radio_,
    Slider_,
    countries,
} from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatDate } from '../utils/formatDates';
import { useTranslation } from 'react-i18next';

const UserFilters = styled.div``;

const StatusOptions = styled.div`
    /* padding-left: 2rem; */
    padding-top: 1rem;
`;


export const Subscriber_Filters = (props) => {

    const [categoryOption, setCategoryOption] = useState(props.filters?.category || []);
    const [statusOption, setStatusOption] = useState(props.filters?.status || []);
    const [status, setStatus] = useState('');
    const {t} = useTranslation()

    //const [datesOption, setDatesOption] = useState(props.dates)

    // const sortingOptions = {
    //     dateAsc: { field: 'current_period_start', key: 1 },
    //     dateDesc: { field: 'current_period_start', key: -1 },
    //     priceAsc: { field: 'price', key: 1 },
    //     priceDesc: { field: 'price', key: -1 },
    //     firstname: { field: 'firstname', key: 1 },
    //     lastname: { field: 'lastname', key: 1 },
    // }


    const sortingOptions = {
        none: {sortValue: '', field: '', key: '' },
        priceAsc: {sortValue: 'priceAsc', field: 'totalPrice', key: 1 },
        priceDesc: {sortValue: 'priceDesc', field: 'totalPrice', key: -1 },
        firstname: {sortValue: 'firstname', field: 'firstname', key: 1 },
        lastname: {sortValue: 'lastname', field: 'lastname', key: 1 },
    }
    const handleCategoryChange = (category) => {
       
            setCategoryOption(prevOptions =>
                prevOptions?.includes(category)
                    ? prevOptions?.filter(c => c !== category) // Remove if already selected
                    : [...prevOptions, category]               // Add if not selected
            );
    };

    const handleStatusChange = (status) => {
        setStatusOption(prevOptions =>
            prevOptions?.includes(status)
                ? prevOptions?.filter(c => c !== status) // Remove if already selected
                : [...prevOptions, status]               // Add if not selected
        );
    };
    console.log('statusOption', statusOption);
    

    const handleConversionDateOption = (selections) => {

        props.setDates({...props.dates, renderedConversionDates: selections , renderedLossDates: ''})
     
        if (selections.length === 2) {
            props.setDates(prevDates => ({
              ...prevDates,
              conversionDates: {
                ...prevDates.conversionDates,
                sinceDate: formatDate(selections[0]),
                untilDate: formatDate(selections[1]),
              },
              lossDates: {
                ...prevDates.lossDates,
                sinceDate: '',
                untilDate: '',
              },
            }));
          }
        
    }

    const handleLossDateOption = (selections) => {
        props.setDates({...props.dates, renderedLossDates: selections, renderedConversionDates:''})
     
        if (selections.length === 2) {
            props.setDates(prevDates => ({
              ...prevDates,
              lossDates: {
                ...prevDates.lossDates,
                sinceDate: formatDate(selections[0]),
                untilDate: formatDate(selections[1]),
              },
              conversionDates: {
                ...prevDates.conversionDates,
                sinceDate: '',
                untilDate: '',
              },
            }));
          }
    }

console.log('sorting', props.sorting);
console.log('category options', categoryOption);
console.log('statusOption', statusOption);

    
    useEffect(() => {
        let newFilters = {
            category: ""
        };
        if (categoryOption?.length) newFilters.category = categoryOption;
        if (statusOption?.length && statusOption?.includes('cancel_at_period_end'))  {
            newFilters.cancel_at_period_end = true;
        } else if (statusOption?.length && statusOption?.includes('subject_to_renewal')){
            newFilters.cancel_at_period_end = false;
        } else if (statusOption?.length){
             newFilters.status = statusOption;
        }


        console.log('newFilters', newFilters);
        
         props.setFilters(newFilters)
        // test
        
    },[categoryOption, statusOption])
 
    return (
        <UserFilters>   
            <Slider_ 
                title={t("Filters")} 
                text={t("Apply")}
                onClose = {() => props.toggleSlider(false)}
                onClick = {() => props.toggleSlider(false)}
            >
            <Builder_ title={t('Sort')}>
                <Dropdown_>
                    <select
                        value={props.sorting?.sortValue}
                        onChange={(e)=> props.setSorting({ ...props.sorting, ...sortingOptions[e.target.value] })}
                    >   
                        {/* <option value='dateAsc'>{t("Date (Asc ↑)")}</option>
                        <option value='dateDesc'>{t("Date (Desc ↓)")}</option> */}
                        <option value='none'>{t("Default")}</option>
                        <option value='priceAsc'>{t("Price (Asc ↑)")}</option>
                        <option value='priceDesc'>{t("Price (Desc ↓)")}</option>
                        <option value='firstname'>{t("First name")}</option>
                        <option value='lastname'>{t("Last name")}</option>
                       
                    </select>
                </Dropdown_>    
            </Builder_>
            <Builder_ title={t("Category")}>
                    <Checkbox_ 
                        text="Questad"
                        id="dailyprofit"
                        // name = 'Category'
                        checked={categoryOption?.includes('dailyprofit')}
                        onClick={() => handleCategoryChange('dailyprofit')}
                    />

                    <Checkbox_ 
                        text="Adsnap"
                        id="lois"
                        // name = 'Category'
                        checked={categoryOption?.includes('lois')}
                        onClick={() => handleCategoryChange('lois')}
                    />
                    <Checkbox_
                        text="Pocket agency"
                        id="bond"
                        // name = 'Category'
                        checked={categoryOption?.includes('bond')}
                        onClick={() => handleCategoryChange('bond')}
                    />
                   
                
            </Builder_>

            <Builder_ title={t("Status")}>

            <Dropdown_>
                    <select
                        value={status}
                        onChange={(e)=> setStatus(e.target.value)}
                    >   
                        <option value='all'>{t("All")}</option>
                        <option value='active'>{t("Active")}</option>
                        <option value='inactive'>{t("Inactive")}</option>
                    </select>
                </Dropdown_>   
                {status === 'active' ? 
                    <StatusOptions>
                        <Checkbox_
                            text="Subject to renewal"
                            id="subject_to_renewal"
                            name = 'Status'
                            checked={statusOption?.includes('subject_to_renewal')}
                            onClick={() => handleStatusChange('subject_to_renewal')}
                        />
                        <Checkbox_ 
                            text="Trial"
                            id="trial"
                            name = 'Status'
                            checked={statusOption?.includes('trialing')}
                            onClick={() => handleStatusChange('trialing')}
                        />
                        <Checkbox_ 
                            text="Canceled at period end"
                            id="cancel_at_period_end"
                            name = 'Status'
                            checked={statusOption?.includes('cancel_at_period_end')}
                            onClick={() => handleStatusChange('cancel_at_period_end')}
                        />
                    </StatusOptions>
                    :status === 'inactive' ? 
                    <StatusOptions>
                        <Checkbox_ 
                            text="Canceled"
                            id="canceled"
                            name = 'Status'
                            checked={statusOption?.includes('canceled')}
                            onClick={() => handleStatusChange('canceled')}
                        />
                        <Checkbox_ 
                            text="Past due"
                            id="past_due"
                            name = 'Status'
                            checked={statusOption?.includes('past_due')}
                            onClick={() => handleStatusChange('past_due')}
                        />
                    </StatusOptions>
                :null }
            </Builder_>
            {/* <Builder_ title={t("Subscriber conversion count by date and tool (all time = n)")}>
                <Dates_ 
                    onChange={handleConversionDateOption} 
                    value={props.dates.renderedConversionDates} 
                    selectRange={true} 
                    dateFullCellRender={(date) => date.date()} 
                    maxDate={new Date()} 
                />
            </Builder_> */}
            {/* <Builder_ title={t("Subscriber loss count by date and tool (all time = n)")}>
                <Dates_ 
                    onChange={handleLossDateOption} 
                    value={props.dates.renderedLossDates} 
                    selectRange={true} 
                    dateFullCellRender={(date) => date.date()} 
                    maxDate={new Date()} 
                />
            </Builder_> */}
            </Slider_>
        </UserFilters>
    );
};
