import { AlertContext, Builder_, Dropdown_, FormGroup, FormList, Input_, Slider_ } from 'monica-alexandria'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { edit_pocket_agency_sub, search_active_products } from '../../../../services/api/Mocks'

const EditPocketAgencyPlan = styled.div`


`

const PlanSearch = styled(FormGroup)``;


const ElasticSuggestions = styled.ul`
  margin-top: 1rem;
  background: ${(props) => props.theme.background};
  box-shadow: ${(props) => props.theme.out};
  border-radius: var(--smallRadius);
  overflow: hidden;
  transition: all 0.5s ease;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
    padding: var(--normalPads);
    transition: background 0.5s;

    &:hover {
      background: ${(props) => props.theme.low};
    }
  }

  .ElasticSelected {
    background: ${(props) => props.theme.low};
  }
`;

const ElasticList = styled(FormList)`
  h5 {
    padding: var(--smallPads);
  }
`;
export const Edit_Pocket_Agency_Plan = (props) => {
    const {t} = useTranslation();
    const {params} = useParams();
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [highlighted, highlight] = useState(0);
    const [planValue, setPlanValue] = useState('');
    const [selectedPlan, setSelectedPlan] = useState('')
    const { alerts, setAlerts } = useContext(AlertContext);
    const [interval, setInterval] = useState('')
    const [plans, setPlans] = useState([])
    const planSearchRef = useRef(null);


    const handlePlanSelection = (plan) => {
        setSelectedPlan((prev) => {
        const updatedPlan = plan;
        setShowSuggestions(false);
        setPlanValue(`${updatedPlan?.name} ${updatedPlan.amount / 100}${updatedPlan?.currency}`);
        return updatedPlan;
        });
    };

      const getAllActiveProducts = (interval, plan) => {
        if (interval) setInterval(interval)
        // if (plan) setPlanValue(plan)
        let data={
          active:true,
          interval:interval,
          searchQuery: plan,
          category:'bond'
        }
        search_active_products(data)
        .then((res) => {
            console.log('getAllActiveProducts', res);
            if(res?.data?.data?.code === 'P4000')  setAlerts([...alerts,{ message: { text: t('Couldnt find any plans for this interval'), status: 'Error' } }]);
            else {
              setPlans(res.data?.data)
              setShowSuggestions(true)
            }
          })
        .catch((err) => {});
      };
    
    

  const editPocketAgencySub = () => {
    let data={
      userId: props.userId,
      planId : selectedPlan?._id,
      subId: props.pocketAgencySub?.subId,
      oldSub: props.pocketAgencySub
    }

    console.log('data', data);
    
    edit_pocket_agency_sub(data)

    .then((res) => {
        console.log('editPocketAgencySub', res);
        
        if (res.data?.code === 'S2005')  {
          setAlerts([...alerts,{ message: { text: t('Plan changed successfully'), status: 'Success' } }]);
          props.setEditPocketAgency(false)
          props.fetchSubscriberInfo();
        }else {
          setAlerts([...alerts,{ message: { text: t('There was an error. Please try again later..'), status: 'Error' } }]);

        }
      
      })
    .catch((err) => {});
  };
  console.log('props.pocketAgencySub', props.pocketAgencySub);
  console.log('plans', plans);

  useEffect(() => {
    function handleClickOutside(event) {
        if (planSearchRef.current && !planSearchRef.current.contains(event.target)) {
            setShowSuggestions(false);
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, []);


  return (
    <EditPocketAgencyPlan>
        <Slider_
          title={t("Change plan")} 
          text={t("Apply")}
          onClose = {() => props.setEditPocketAgency(false)}
          onClick = {() => editPocketAgencySub()}
        >   
           <Builder_ title={t('Select interval')}>
                <Dropdown_>
                    <select
                    value={interval}
                    onChange={(e)=>getAllActiveProducts(e.target.value, '')}
                    >   
                        {/* <option value='30'>{t("30 days")}</option> */}
                        <option disabled value=''>{t("Interval")}</option>
                        <option value='month'>{t("Monthly")}</option>
                        <option value='year'>{t("Yearly")}</option>
                        <option value='day'>{t("Daily")}</option>
                        <option value='week'>{t("Weekly")}</option>
                        
                    </select>
                </Dropdown_>    
            </Builder_>
            {interval ? 
                <Builder_ title={t('Select an existing plan')}>

                <PlanSearch ref={planSearchRef} className="ElasticSearch">
                    <Input_
                    id="elasticSearch"
                    placeholder={''}
                    autoComplete="off"
                    type="text"
                    value={planValue}
                    onChange={(e) => {
                        setPlanValue(e.target.value); // Directly update the state
                        setShowSuggestions(true);
                        getAllActiveProducts(interval, e.target.value); // Avoid rapid calls
                    }}
                    // onKeyPress={(e) => detectEnter(e)}
                    />
                {showSuggestions?
                  <ElasticSuggestions className="ElasticSuggestions">
                    {plans?.map((plan, i) => (
                        <li
                        className={
                            i === (highlighted % plans?.length) - 1
                            ? "ElasticSelected"
                            : ""
                        }
                        key={i}
                        onClick={() => handlePlanSelection(plan)}
                        
                        >
                        <p>{plan?.name} {plan.amount/100} {plan?.currency}</p>
                        </li>
                    ))}
                    </ElasticSuggestions>  
                    :null}    
                </PlanSearch>
                </Builder_>
            : null}
        </Slider_>
    </EditPocketAgencyPlan>
  )
}
