import {ThemeContext, logo,Card, device, Slider_, Actions_, Grey_Link } from 'monica-alexandria';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SubscriberEdit=styled.div`

`
const SubscriberInfo = styled.div`
  padding-bottom: 2rem;
`

const SubscriberCardWrapper = styled.div`
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem; */
  
  `
const SubscriberCard = styled(Card)`
  cursor: pointer;
  background: ${p => p.theme.mid};
    ;
  /* box-shadow: none; */
  padding: 2rem;
  margin-bottom: 2rem;
`

const SubscriptionCardInfo = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  @media ${device.lg} {
    flex-direction: column;
  }

  @media ${device.md} {
    flex-direction: row;
  }

  @media ${device.sm} {
    flex-direction: column;
  }
`

const SubscriptionCardGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: .5rem;
  margin-top: 2rem;

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }

  @media ${device.md} {
    grid-template-columns: auto 1fr;
  }

  @media ${device.sm} {
    grid-template-columns: 1fr;
  }
`
const SubscriptionApp= styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: right;
    img{
      width: 4rem;
    }
`
const SubscriptionCardLeft = styled.div``

const SubscriptionCardAmountPaid = styled.div`
    display: flex;
    align-items: baseline;Pocket

    h1{
      font-size: 4rem;
      text-align: center;
    }
`

const EditSubscriptionWrapper = styled.div`
`
export default function Subscriber_Edit(props) {
    const {t} = useTranslation();

    const {theme} = useContext(ThemeContext);
    const [editSubscription, setEditSubscription] = useState(false)

    console.log('PROPS', props.selectedSub);
    const SubscriptionStatus = {
      active: (t("active")),
      past_due: (t("payment failed"))
  };

  console.log('SELECTED', props.selectedSub);
  
  return (
    <SubscriberEdit>
        <Slider_
            title={t("Subscriber")} 
            text={t("Apply")}
            onClose = {() => props.setSelectedSub(false)}
            onClick = {() => props.setSelectedSub(false)}
        >  
     
        <SubscriberInfo>
          <h3>{props.selectedSub?.data[0]?.firstname} {props.selectedSub?.data[0]?.lastname}</h3>
          <p>{props.selectedSub?.username}</p>
        </SubscriberInfo>
        {!editSubscription ?
  
        <SubscriberCardWrapper>
        {props.selectedSub?.data?.map((subscription) => (
          <SubscriberCard key={subscription._id} onClick={()=> setEditSubscription(true)}  >
            <SubscriptionCardInfo>
              <SubscriptionCardLeft>
                <SubscriptionCardAmountPaid>
                  <h1>€ {subscription.price / 100}</h1> {/* Convert price to euros */}
                  <h5>,00</h5>
                </SubscriptionCardAmountPaid>
                <SubscriptionCardGrid>
                  <p>{t("Payment interval")}:</p>
                  <h5>{t("30 days")}</h5>
                  <p>{t("Subscription Status")}:</p>
                  <h5>{subscription.status}</h5>
                  <p>{t("Next payment")}:</p>
                  <h5>
                    {!subscription.subscriptionCancelOnPeriodEnd
                      ? new Date(subscription.current_period_end).toLocaleDateString()
                      : t("Next scheduled payment canceled")}
                  </h5>
                  {subscription.amountTopUp ? (
                    <>
                      <p>{t("Next invoice is set to")}:</p>
                      <h5>€ {subscription.amountTopUp / 100},00</h5>
                    </>
                  ) : null}
                </SubscriptionCardGrid>
              </SubscriptionCardLeft>
              {/* <SubscriberCardRight> */}
                {subscription?.category === 'lois' ? 
                <SubscriptionApp>
                  <img src={logo.lois[theme]} />
                  <h6>Adsnap</h6>
                </SubscriptionApp>
                : subscription?.category === 'bond' ?
                  <SubscriptionApp>
                  <img src={logo.bond[theme]} />
                  <h6>Pocket</h6>
                  <h6>agency</h6>
                </SubscriptionApp>
                : 
                <SubscriptionApp>
                  <img src={logo.dailyprofit[theme]} />
                  <h6>Questad</h6>
                </SubscriptionApp>
                }
              {/* </SubscriberCardRight> */}
            </SubscriptionCardInfo>
          </SubscriberCard>
        ))}
        </SubscriberCardWrapper>
        : 
          <EditSubscriptionWrapper>
             <Actions_>
                <Grey_Link
                  noPadding
                  text = {'Back'}
                  onClick = {()=>setEditSubscription(false)}
                  iconLeft = {'Back'}
                />
              </Actions_>
          </EditSubscriptionWrapper>
        }
        </Slider_>
    </SubscriberEdit>
  )
}
