import {Alert_,Filters_,Grey_Link,Main_,Main_Plain,PageFull,PageTitle,Partial_Loader,Slider_,TBody,TD,TH,THead,TR,TRH,Table,TableDesktop, Builder_, Google_Search, PageAlwaysVisible, AlertContext} from "monica-alexandria";
import React, {useEffect, useState, useReducer, useContext } from "react";
import { useTranslation } from "react-i18next";
import { get_plans } from "../../../services/api/Plans";
import { prettifyPrice } from "../../../helpers/prettifyFunctions";
import Slider_Current_Plan from "./partials/Slider_Current_Plan";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import { useDebounce } from 'use-debounce'; // Example import statement, adjust as necessary
import i18n from "../../../localization/i18next";

const clients = require("../../../config/index").config;
const client = process.env.REACT_APP_CLIENT;

const SearchContainer = styled.div`
  display: flex;
  align-items: center; /* Center align items vertically */
  /* gap: 10px;  */
  /* Adds some space between search input and button */
  width: 100%; /* Ensures the container uses full width */
`;

const GoogleSearchWrapper = styled.div`
  flex-grow: 1; /* Allows the search input to take the remaining width */
  display: flex;
  max-width: calc(100% - 80px); /* Optional: limits the max width to avoid overflow, assuming the button width is about 80px */

`;

const MainButton = styled(Main_)`
  flex-shrink: 0; /* Prevents the button from shrinking */
`;

const PageTitleInner = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export default function PLANS_V3() {
  const protocol = process.env.REACT_APP_GRIPHOOK_FRONTEND_PROTOCOL;
  const url = process.env.REACT_APP_GRIPHOOK_FRONTEND_HOST;
  const port = process.env.REACT_APP_GRIPHOOK_FRONTEND_PORT;
  const griphookFrontURL = `${protocol}${url}${port}`;

  const lang = i18n.language ? i18n.language : "el";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {alerts, setAlerts} = useContext(AlertContext);

 
  // useReducer is like useState, but you don't need to use the spread operator e.g. setState({...oldData, newData})
  // instead, you need to write it like this: setState({newData})

  const [langs, setLangs] = useReducer((a, b) => ({ ...a, ...b }), {
    targetLang: "el",
    displayedLang: "el",
  });
  
	const [slider, setSlider] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [plans, setPlans] = useState([]);
  const [searchResults, setSearchResults] =  useState([]);
  const [paymentLink, setPaymentLink] = useState();
  const [displayedPlans,setDisplayedPlans] = useState([]);
  
  const handlenav = (toPath) => {
    window.location.assign(
      `${clients[client]}/${lang}/akihiko/${client}/${toPath}`
    );
  };

  function prettifyCurrency(currency) {
    if (currency == "eur") return "€";
    else return currency;
  }

  const handleCloseSlider = () => {
		setSelectedPlan(null);
		setSlider('');
	};

	const handleEditClick = (plan) => {
		setSelectedPlan(plan);
		setSlider('edit')
	};

  const applySearchResults = () => {
    if (searchResults?.input) setDisplayedPlans(searchResults?.data);
    else setDisplayedPlans(plans);
  };

  console.log('plans', plans);
  
  useEffect(() => {
    get_plans()
      .then((res) => {
        setPlans(res.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => { applySearchResults(); }, [searchResults]);

  useEffect(() => {
    if (plans?.length > 0) setDisplayedPlans(plans);
  }, [plans]);


  return (
    <PageFull className={slider==="edit" ? "HideScroll" : ""}>
      <Alert_ />
     {slider==='edit' ? 
        <Slider_Current_Plan
          paymentLink={paymentLink}
          plan={selectedPlan}
          setPaymentLink={setPaymentLink}
          selectedPlan={selectedPlan}
          handleCloseSlider={handleCloseSlider}
        />
         
        : null}
      <PageAlwaysVisible>
        <PageTitle cta>
          <PageTitleInner>
            <Grey_Link noPadding iconLeft="Back" onClick={() => navigate(-1)} />
            <h1>{t("All plans")}</h1>
          </PageTitleInner>
          <MainButton iconLeft="Add" text="New" onClick={()=>handlenav("create-plan")}/>
        </PageTitle>
        <Builder_>
              <Google_Search
                debounceTime={750}
                minMatchCharacters={1}
                maxMatchCharacters={50}
                disabled={false}
                isCaseSensitive={false}
                maxAcceptedScore={0.6}
                defaultValue={""}
                file={plans}
                sanitize={false}
                placeholder="Search"
                fileKeys={['name']}
                setResults={(data) => setSearchResults(data)}
              />
        </Builder_>
          {displayedPlans?.length ?
          <TableDesktop>
            <Table>
              <THead>
                <TRH>
                  <TH></TH>
                  <TH>#</TH>
                  <TH>{t("Plans")}</TH>
                  <TH>{t("Price")}</TH>
                </TRH>
              </THead>
              <TBody>
              {displayedPlans?.map((plan, i) => ( 
                <TR key={i}>
                  <TD>
                    <Main_Plain onClick={() => handleEditClick(plan)} noPadding iconLeft="Edit"/>
                  </TD>
                  <TD>{i + 1}</TD>
                  <TD>{plan?.name}</TD>
                  <TD>{`${prettifyPrice(plan?.amount)}${prettifyCurrency(plan?.currency)}`}</TD>
                </TR>
              ))}
              </TBody>
            </Table>
          </TableDesktop>
        :
          <Partial_Loader />
        }
      </PageAlwaysVisible>
    </PageFull>
    
  );
}