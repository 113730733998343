import { get_total_revenue, get_meta_adspend, get_user_total_revenue, get_meta_adspend_all_time } from "../../../../services/api/Subscriptions"

export const showKPIS = async(subscribers, setSubscribers, showLoading, currPage, currPageFilters, subsFromAxios) => {

    console.log('------------ KPIS START ------------------')

    let newSubs = JSON.parse(JSON.stringify(subsFromAxios ));

    let rows;

    if (currPageFilters) rows = currPageFilters ? subscribers?.subscribers_with_kpis: [];
    else rows = currPage ? subscribers?.subscribers_with_kpis: [];


    for (let i=0; i<subsFromAxios?.length; i++) {

        console.log('iteration', i)
        let currentSub = subsFromAxios[i];

        let questAds = currentSub?.data?.filter(sub => sub?.category == 'dailyprofit');
        // let subIds = questAds?.map(quest => quest?.subId);
        let cusId = questAds?.length ? questAds[0]?.cusId : false;

        let kpis = await get_total_revenue(cusId);

        let subIds = [];
        if (kpis?.kpis?.length) {
            subIds = kpis?.kpis?.map(sub => sub?.questId);
        }
        // let metaAdspent = await get_meta_adspend(subIds);
        let metaAdspent = await get_meta_adspend_all_time(subIds);
        let userKpis = await get_user_total_revenue(currentSub?.data[0]?.userId)

        newSubs[i].totalRevenue = kpis?.totalRevenue;
        newSubs[i].totalFee = kpis?.totalFee;
        newSubs[i].totalExpectedAdSpend = kpis?.totalExpectedAdSpend;
        newSubs[i].metaAdspent = metaAdspent;
        newSubs[i].totalUserRevenue = userKpis?.totalSpend || 0;
        
        rows.push(newSubs[i])

        console.log(i==subsFromAxios?.length - 1)
        if (i==subsFromAxios?.length - 1) {
            setSubscribers({...subscribers, subscribers_with_kpis: rows});
            showLoading(false);
        }
        else {
            setSubscribers({...subscribers, subscribers_with_kpis: rows, show_loader: true});
        }
    }
}