import React from 'react'
import styled from 'styled-components'

const GraphBox = styled.div`
    /* background: ${p => p.background ? `p.theme.${p.background})` : p.theme.low} !important; */
  background:${p => p.theme.low};
  box-shadow: ${p => p.selected ? p.theme.inFocus : 'none'};
  border-radius: var(--smallRadius);
  padding: 2rem;
  cursor: ${p => p.unclickable ? "default" : "pointer" };
  transition: all .5s;

  &:hover{
    box-shadow: ${p => p.selected ? p.theme.inFocus :p.unclickable ? "none"  : p.theme.out};
    transition: all .5s;
  }
`

const GraphBoxBorder = styled.div`
  border-radius: .5rem;
  border: 2px solid ${p => p.color ? `var(--${p.color})` : p.theme.color};
  height: 100%;
  padding: 0 1rem 1rem 1rem;
  
`

const GraphBoxTitle = styled.div`
  background:${p => p.theme.low};
  /* background: ${p => p.background ? `p.theme.${p.background})` : p.theme.low} !important; */

  transform: translateY(-1rem);
  display: inline-block;
  padding: 0 1rem;

  h6{
    color: ${p => p.color ? `var(--${p.color})` : p.theme.color} !important;
  }
`

const GraphBoxValue = styled.div`
  text-align: center;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  gap: 0.2rem;

  h1{
    color: ${p => p.color ? `var(--${p.color})` : p.theme.color} !important;
  }
`

export default function Graph_Box(props) {

  function formatYAxis(number) {
    if (!number) 
      return;
    // Check if the number is one thousand or more but less than a million
    if (number >= 1000 && number < 1000000) {
        return (number / 1000)?.toFixed(1)?.replace(/\.0$/, '') + 'K';
    }
    // Check if the number is one million or more
    else if (number >= 1000000) {
        return (number / 1000000)?.toFixed(1)?.replace(/\.0$/, '') + 'M';
    }
    // Handle numbers less than 1000 with up to two decimal places
    else if (!Number?.isInteger(number)) {
        return number?.toFixed(2);
    }
    // Handle integer values under 1000
    return number;
}

  return (
    <GraphBox background={props.background} unclickable={props.unclickable} onClick={props.onClick} selected={props.selected} key={props.key} id={props.id}>
      <GraphBoxBorder color={props.color} selected={props.selected}>
        <GraphBoxTitle color={props.color}  background={props.background} selected={props.selected}>
          <h6>{props.kpiTitle}</h6>
        </GraphBoxTitle>
        <GraphBoxValue selected={props.selected} color={props.color}>
          {/* <h1>{formatYAxis(props?.kpiValue)}</h1> */}
          <h1>{props?.kpiValue}</h1>
          <p>{props.kpiDesc}</p>
        </GraphBoxValue>
      </GraphBoxBorder>
    </GraphBox>
  )
}
