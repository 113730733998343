import { Alert_, AlertContext, Confirmation_, Grey_Link, Main_Plain, PageAlwaysVisible, PageSixty, PageTitle } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { create_fake_invoice, delete_fake_invoice, edit_fake_invoice, get_invoices, get_subscriber_info } from '../../../services/api/Mocks';
import { useNavigate, useParams } from 'react-router-dom';
import { Invoice_Card } from '../../partials/invoiceCard/Invoice_Card';
import { Fake_Pocket_Agency } from '../../partials/fakePocketAgency/Fake_Pocket_Agency';
import styled from 'styled-components';

const SubscriberInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  gap:1rem;
`
const SubscriberInfoUser = styled.div`
  border-radius: var(--smallRadius);
  background: ${p=>p.theme.mid};
  padding: var(--smallRadius);
`
export default function CUSTOM_POCKET_AGENCY() {

    // useEffect(() => {
    //   fetchSubscriberInfo();
  
    // }, []);
    const params= useParams();
    const {t} = useTranslation();
    const [invoices, setInvoices] = useState([]) 
    const navigate = useNavigate();
    const [addPocketAgency, setAddPocketAgency] = useState(false)
    const [subscriber, setSubscriber] = useState('')
    const { alerts, setAlerts } = useContext(AlertContext);
    const [dltInvoice, setDltInvoice] = useState(false)
    const [editInvoice, setEditInvoice] = useState(false)

    let payload = {
      userId:params?.id,
      category: 'bond',
      fakeInvoice: true
     };
    
    const [pocketAgencyInfo, setPocketAgencyInfo] = useState('')

    const fetchSubscriberInfo = async() => {
  
      get_subscriber_info({userId: params?.id}).then(async(res) => {

        console.log('RESSS get_subscriber_info', res);
        
         setSubscriber({...subscriber, subs: res?.data?.subs, info: res?.data?.user});

          let customer = res?.data?.subs[0]?.cusId;
          let subIds = res?.data?.subs?.map(sub => sub.subId);
          console.log('subIds', subIds);
         
      
        })  
        
    };


    const createFakeInvoice = () => {
      let data = {
          userId: subscriber?.info?.userId,
          customerEmail: subscriber?.info?.username,
          amount: pocketAgencyInfo?.amountPaid ,
          end: pocketAgencyInfo?.end,
          description: pocketAgencyInfo?.description,
          revenueType: pocketAgencyInfo?.revenueType  
      };
  
      console.log('CALLED', data);
      
      create_fake_invoice(data)
        .then((res) => {
          console.log('RESsssssssssssssssssssssssssssssssssss', res);
           if (res?.data?.code ==='I2002'){ 
              getInvoices(payload);
              setAlerts([...alerts, { message: { text: 'Pocket agency subscription created successfully!', status: "Success"}} ])
              setAddPocketAgency(false)
           }else{
              setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
           }
          })
        .catch((err) => {});
  };
  


  const edit_invoice = () => {
    let data = {
        // userId: editInvoice?.id,
        _id :editInvoice?._id, 
        amountPaid: editInvoice?.amountPaid ,
        end: editInvoice?.end,
        description: editInvoice?.description,
        revenueType: editInvoice?.revenueType  
    };

    console.log('editInvoice!!', editInvoice);
    
    edit_fake_invoice(data)
      .then((res) => {
        console.log('RESsssssssssssssssssssssssssssssssssss', res);
        if (res?.data?.code ==='I2007'){ 
          setEditInvoice(false)
          getInvoices(payload);
          setAlerts([...alerts, { message: { text: 'Pocket agency invoice updated successfully!', status: "Success"}} ])
        }else{
            setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
        }
      })
      .catch((err) => {});
};



  const delete_invoice = () => {
    const sub = subscriber?.subs?.find(sub=> sub?.category === 'bond' && sub?.fakeSub);
            console.log('SUB', sub);
            
        delete_fake_invoice(dltInvoice?._id, dltInvoice?.userId, sub?._id)
          .then((res) => {
            console.log('RESsssssssssssssssssssssssssssssssssss', res);
            if (res?.data?.code ==='I2003'){ 
                setDltInvoice(false)
                getInvoices(payload);
              
                setAlerts([...alerts, { message: { text: 'Pocket agency invoice deleted successfully!', status: "Success"}} ])

            }else{
                setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
            }
            setDltInvoice(false)
            })
          .catch((err) => {});
    };

    const getInvoices = (payload)=>{
        get_invoices(payload)
        .then(res =>{

            if(res?.data?.data?.code === "P4005")  
              setAlerts([...alerts,{message: {text: t('Invoices not found'), status: 'Loading',}, },]);
            else setInvoices(res?.data?.data)    
        }
    )}


    useEffect(() => {

      getInvoices(payload);
      fetchSubscriberInfo();
    }, []);

    console.log('INVOICES', invoices);
    console.log('editInvoice', editInvoice);
    

    
  return (
    <PageSixty>
      <Alert_ />
      {dltInvoice !== false ?
        <Confirmation_
          message={t("Are you sure you want to delete this subscription?")}
          yes="Yes"
          onYes={() => delete_invoice()}
          onNo={() => setDltInvoice(false)}
          no="No"
        />
      : null}
      <Alert_/>
       {addPocketAgency ? 
          <Fake_Pocket_Agency
            closeSlider={()=>setAddPocketAgency(false)}
            pocketAgencyInfo={pocketAgencyInfo}
            setPocketAgencyInfo={setPocketAgencyInfo}
            onSave={createFakeInvoice}
          />
        : null}
          {editInvoice ? 
            <Fake_Pocket_Agency
              closeSlider={()=>setEditInvoice(false)}
              pocketAgencyInfo={editInvoice}
              setPocketAgencyInfo={setEditInvoice}
              onSave={edit_invoice}
              // subscriber={subscriber}
            />
        : null}
      <PageAlwaysVisible>

        <PageTitle>
            <Grey_Link iconLeft='Back' noPadding onClick={()=>navigate(-1)}/>
            <h1>{subscriber?.info?.firstname} {subscriber?.info?.lastname}</h1>
        </PageTitle>
        <SubscriberInfo>
          <SubscriberInfoUser>
            <h6>{subscriber?.info?.username} </h6>
          </SubscriberInfoUser>
          <Main_Plain text='Invoice' onClick={()=> setAddPocketAgency(true)} noPadding iconLeft='Add' />
        </SubscriberInfo>
        
        {invoices?.length>0 && invoices?.map((invoice, i) => (
          <Invoice_Card
            amountPaid={invoice?.amountPaid/100}
            onDelete={()=> setDltInvoice(invoice)}
            onEdit={()=>setEditInvoice(invoice)}
          >
            <>
            <p>{t("Period")}:</p>
            <h5>{new Date(invoice?.start * 1000).toLocaleDateString('el-GR')} - {new Date(invoice?.end * 1000).toLocaleDateString('el-GR')}</h5>
            {invoice?.revenueType ? 
              <>
                <p>{t("Revenue")}:</p>
                <h5>{invoice?.revenueType}</h5>
              </>
            :null}     
            {invoice?.description ? 
              <>
                <p>{t("Description")}:</p>
                <h5>{invoice?.description}</h5>
              </>
            :null}            
            </>
          </Invoice_Card>
          
        ))}
      </PageAlwaysVisible>
    </PageSixty>
  )
}
