import React from 'react'
import styled from 'styled-components'

const SubColorCoding = styled.div`
    display: flex;
    align-items: center;
    gap: .5rem;
    max-width: 20rem;
    flex-wrap: wrap; 
`

const SubColorCodingBullet = styled.div`
    /* height: 1rem;
    width: 1rem; 
    border-radius: 50%; */
    border-radius: 0.4rem;
   
    background-color: ${p=> 
        p.subscription === 'past_due' ? '#FDF0D8' 
        :p.subscription === 'canceled' ? '#FDD8D8'
        :p.subscription === 'trialing' ? '#F6DFF2'
        : '#CFF2D7'
    };
    border: 1px solid;
    border-color: ${p=> 
        p.subscription === 'past_due' ? 'var(--warning)' 
        :p.subscription === 'canceled' ? 'var(--error)'
        :p.subscription === 'trialing' ? 'var(--focus)'
        : 'var(--success)'
    };
    

    h5{
        white-space: nowrap;

        letter-spacing: 0.5px;
        padding: 0 .5rem;
        color: ${p=> 
            p.subscription === 'past_due' ? 'var(--warning)' 
            :p.subscription === 'canceled' ? 'var(--error)'
            :p.subscription === 'trialing' ? 'var(--focus)'
            : 'var(--success)'
        }
        
    }
`

const SubColorCodingLabel = styled.div`
    display: flex;
    align-items: center;
    gap: .5rem;
    padding-right: .5rem;
    cursor: default;

`

export const Sub_Color_Coding = (props) => {

    // console.log('LOIS', props.loisStatus);
    // console.log('LOIS', props.bond);
    
  return (
    <SubColorCoding>
        {props.bond ?
            <SubColorCodingLabel title={props.bondStatus}>
                <SubColorCodingBullet subscription={props.bondStatus}><h5>Pocket agency</h5></SubColorCodingBullet>
            </SubColorCodingLabel>
        :
            null
        }
        {props.lois ?
        <SubColorCodingLabel title={props.loisStatus}>
            <SubColorCodingBullet subscription={props.loisStatus}><h5>Adsnap</h5></SubColorCodingBullet>
            
            </SubColorCodingLabel>
        :
            null
        }
        {props.dailyprofit ? 
            <SubColorCodingLabel  title={props.dailyprofitStatus}>
                <SubColorCodingBullet subscription={props.dailyprofitStatus}><h5>Questad</h5></SubColorCodingBullet>
            </SubColorCodingLabel>
        :
            null
        }
    </SubColorCoding>
  )
}
