import { Alert_, AlertContext, Builder_, Card, CardsForty, CardsSixty, Confirmation_, device, Dropdown_, Edit_, FormGroup, FormList, Grey_Link, Input_, logo, Main_, Main_Plain, On_Click_Card, PageAlwaysVisible, PageFull, PageSingle, PageSixty, PageTitle, Red_, Slider_, ThemeContext, Toggle_Stat } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { create_fake_invoice, create_fake_sub, delete_fake_sub, edit_fake_sub, edit_pocket_agency_flow, edit_pocket_agency_sub, get_all_active_products, get_invoices, get_plan_details, get_subscriber_info, search_active_products } from '../../../services/api/Mocks'
import { useTranslation } from 'react-i18next'
import { Edit_Pocket_Agency_Plan } from '../stripePocketAgency/partials/Edit_Pocket_Agency_Plan'
import { Edit_Fake_Pocket_Agency } from './partials/Edit_Fake_Pocket_Agency'
import { Add_Pocket_Agency } from './partials/Add_Pocket_Agency'
import { Fake_Pocket_Agency } from '../../partials/fakePocketAgency/Fake_Pocket_Agency'
import { Invoice_Card } from '../../partials/invoiceCard/Invoice_Card'


const PocketAgencySubscriber = styled.div``


const SubscriberInfo = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  gap:1rem; 

  @media ${device.sm} {
   
    display: block;
    padding-left: 1rem;
    padding-bottom: 2rem;
  }

`

const SubscriberInfoInner = styled.div`
  display: flex;
  align-items: center;
  gap:1rem; 

  img{
    width: 3.5rem;
    height: 3.5rem;
    border-radius: var(--smallRadius);
  }

  @media ${device.sm} {
    align-items: left;
  }
`
const SubscriberInfoUser = styled.div`
  border-radius: var(--smallRadius);
  background: ${p=>p.theme.mid};
  padding: var(--smallRadius);
`




const SubscriberCreateBtn = styled.div`
  display: flex;
  justify-content: right;

  padding-bottom: 1rem;
`


export default function POCKET_AGENCY_SUBSCRIBER() {
    const params = useParams();
    const navigate = useNavigate();
  

    const {t} = useTranslation();
    const [subscriber, setSubscriber] = useState('')
    const {theme} = useContext(ThemeContext);
    const [editFakePocketAgency, setEditFakePocketAgency] = useState(false)
    const [addPocketAgency, setAddPocketAgency] = useState(false)
    const { alerts, setAlerts } = useContext(AlertContext);
    const [dltFakeSub, setDltFakeSub] = useState(false)
    const [pocketAgencyInfo, setPocketAgencyInfo] = useState('')
    const [stripeInvoices, setStripeInvoices] = useState(false)
    const [pocketAgencyFlow, togglePocketAgencyFlow] = useState(subscriber?.info?.adAccountFlow)
    const [invoicesSum, setInvoicesSum] = useState([]) 
    
    const [editPocketAgency, setEditPocketAgency] = useState(false)

    const fakeSub = subscriber?.subs?.find(sub=> sub?.category === 'bond' && sub?.fakeSub);
    const hasFakeSub = subscriber?.subs?.some(sub=> sub?.fakeSub);
    const hasPocketAgencySub = subscriber?.subs?.some(sub=>sub?.category === 'bond' && !sub?.fakeSub);
    const pocketAgencySubs = subscriber?.subs?.filter(sub=>sub?.category === 'bond' && !sub?.fakeSub);

    
    const fetchSubscriberInfo = (payload = {}) => {
      const { startDate, endDate } = payload;
  
      get_subscriber_info({ userId: params?.id })
          .then((res) => {
              console.log("get_subscriber_infoRESSS", res);
              setSubscriber({ ...subscriber, subs: res?.data?.subs, info: res?.data?.user });
          })
          .catch((err) => {
              console.error("Error fetching subscriber info:", err);
          });
  };

    // const edit_pocket_agency_sub = () => {
    //       let data = {
    //           userId: params?.id,
    //           _id:pocketAgencyInfo?._id, 
    //           amount: pocketAgencyInfo?.price ,
    //           current_period_end: pocketAgencyInfo?.date,
    //           description: pocketAgencyInfo?.description,
    //       };
  
    //       console.log('CALLED');
          
    //       edit_fake_sub(data)
    //         .then((res) => {
    //           console.log('RESsssssssssssssssssssssssssssssssssss', res);
    //            if (res?.data?.code ==='S2004'){ 
    //               fetchSubscriberInfo();
    //               setAlerts([...alerts, { message: { text: 'Pocket agency subscription created successfully!', status: "Success"}} ])
    //               setAddPocketAgency(false)
    //            }else{
    //               setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
    //            }
    //           })
    //         .catch((err) => {});
    //   };

    
    const getInvoices = (payload)=>{
      get_invoices(payload)
      .then(res =>{
        console.log('get_invoices res', res.data);
        if(res?.data?.data?.code !== "P4005"){
          const invoices= res?.data?.data;
          setInvoicesSum(invoices?.reduce((sum, sub) => sum + (sub?.amountPaid || 0), 0)/100)    
        }else {
          setInvoicesSum(0)
        }
         
         
      }
  )}


    const createFakeInvoice = () => {
      let data = {
          userId: subscriber?.info?.userId,
          customerEmail: subscriber?.info?.username,
          amount: pocketAgencyInfo?.amountPaid ,
          end: pocketAgencyInfo?.end,
          description: pocketAgencyInfo?.description,
          revenueType: pocketAgencyInfo?.revenueType
      };
  
      
      create_fake_invoice(data)
        .then((res) => {
          console.log('RESsssssssssssssssssssssssssssssssssss', res);
           if (res?.data?.code ==='I2002'){ 
              fetchSubscriberInfo();
              setAlerts([...alerts, { message: { text: 'Pocket agency subscription created successfully!', status: "Success"}} ])
              setAddPocketAgency(false)
           }else{
              setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
           }
          })
        .catch((err) => {});
  };
  

      const delete_pocket_agency_sub = () => {
      
        delete_fake_sub(dltFakeSub)
          .then((res) => {
            console.log('RESsssssssssssssssssssssssssssssssssss', res);
            if (res?.data?.code ==='S2005'){ 
              if (hasPocketAgencySub) {
                setDltFakeSub(false)
                setEditFakePocketAgency(false)
                setAlerts([...alerts, { message: { text: 'Pocket agency subscription deleted successfully!', status: "Success"}} ])

              }
              else navigate(-1)
            }else{
                setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
            }
     
            })
          .catch((err) => {});
    };

    const handle_pocket_agency_flow = (flow) => {

      let data={
        userId: params?.id,
        adAccountFlow: flow
      }
      
      edit_pocket_agency_flow(data)
        .then((res) => {
           console.log('RESsssssssssssssssssssssssssssssssssss', res);
          if (res?.data?.code ==='S6004'){ 
              fetchSubscriberInfo();
              setAlerts([...alerts, { message: { text: 'Pocket agency flow updated successfully!', status: "Success"}} ])
          }else{
              setAlerts([...alerts, { message: { text: 'There was an error. Please try again...', status: "Error"}} ])
          }
   
          })
        .catch((err) => {});
  };

  useEffect(() => {
    let payload = {
      userId:params?.id,
      category: 'bond',
      fakeInvoice: true
     };
    
    getInvoices(payload)
    // fetchSubscriberInfo();

  }, []);

  useEffect(() => {
  
    fetchSubscriberInfo();

  }, []);


  console.log('pocketAgencyFlow', pocketAgencyFlow, subscriber?.info?.adAccountFlow);
  console.log('pocketAgencyy Inf', pocketAgencyInfo);
  
  return (
    <PocketAgencySubscriber>
      <Alert_ />
      {dltFakeSub !== false ?
      <Confirmation_
        message={t("Are you sure you want to delete this subscription?")}
        yes="Yes"
        onYes={() => delete_pocket_agency_sub()}
        onNo={() => setDltFakeSub(false)}
        no="No"
      /> : null}
      <Alert_ />
      {editPocketAgency ? 
        <Edit_Pocket_Agency_Plan
          fakeSub = {fakeSub}
          pocketAgencySub={editPocketAgency}
          setEditPocketAgency={setEditPocketAgency}
          fetchSubscriberInfo={fetchSubscriberInfo}
          userId={params.id}
        />
      : null}
        {addPocketAgency ? 
          <Fake_Pocket_Agency
            closeSlider={()=>setAddPocketAgency(false)}
            pocketAgencyInfo={fakeSub}
            setPocketAgencyInfo={setPocketAgencyInfo}
            onSave={createFakeInvoice}
            // subscriber={subscriber}
          />

      : null}
    
        <PageFull>

            <PageTitle>
                <Grey_Link iconLeft='Back' noPadding onClick={()=>navigate(-1)}/>
                <h1>{subscriber?.info?.firstname} {subscriber?.info?.lastname}</h1>
            </PageTitle>
        </PageFull>
        <PageSixty>
          <PageAlwaysVisible>
            <SubscriberInfo>
              <SubscriberInfoInner>
                <img src={logo.bond[theme]} title='Pocket Agency' />
                <SubscriberInfoUser>
                  <h6>{subscriber?.info?.username} </h6>
                </SubscriberInfoUser>
              </SubscriberInfoInner>
                  {/* <p>Created at: {new Date(subscriber?.info?.createdAt).toLocaleDateString('el-GR')} </p> */}
              </SubscriberInfo>
              <Builder_ title={t('Manage Pocket Agency Flow')}>
                  <Toggle_Stat 
                    text='Pocket Agency Flow' 
                    active={subscriber?.info?.adAccountFlow} 
                    onClick={()=>{
                      // togglePocketAgencyFlow(!pocketAgencyFlow)
                      handle_pocket_agency_flow(!subscriber?.info?.adAccountFlow)
                    }}
                    />    
              </Builder_>
              <SubscriberCreateBtn>
                {!hasFakeSub ?  
                  <Main_Plain text='Pocket Agency Subscription' onClick={()=> setAddPocketAgency(true)} noPadding iconLeft='Add' />
                : null}  
              </SubscriberCreateBtn>
              {pocketAgencySubs?.map((sub, i) => (
                <Invoice_Card
                  amountPaid={sub?.price/100}
                  label='Stripe'
                  onEdit={()=> setEditPocketAgency(sub)}
                  onClick={()=> navigate(`/${params.lang}/akihiko/griphook/subscribers/pocket-agency/stripe/${subscriber?.info?.userId}/${sub?.cusId}`)}
                >
                  <p>{t("Payment interval")}:</p>
                 
                  <h5>{new Date(sub?.current_period_start).toLocaleDateString('el-GR')} - {new Date(sub?.current_period_end).toLocaleDateString('el-GR')}</h5>
                  {new Date()<new Date(sub?.current_period_end) && sub.newPlan?.amount ? (
                      <>
                        <p>{t("Next invoice is set to")}:</p>
                        <h5>€ {sub.newPlan?.amount / 100},00</h5>
                      </>
                    ) : null}
                </Invoice_Card>
              ))}
              {hasFakeSub ? 
                <Invoice_Card
                  label='Custom'
                  amountPaid={invoicesSum}
                  onClick={()=> navigate(`/${params.lang}/akihiko/griphook/subscribers/pocket-agency/custom/${subscriber?.info?.userId}`)}
                >
                </Invoice_Card>
              : null}
          </PageAlwaysVisible>
        </PageSixty>
    </PocketAgencySubscriber>
  )
}
