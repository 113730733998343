import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { get_invoices, get_subscriber_info } from '../../../services/api/Mocks';
import { AlertContext, Grey_Link, Main_Plain, PageAlwaysVisible, PageSixty, PageTitle } from 'monica-alexandria';
import { Invoice_Card } from '../../partials/invoiceCard/Invoice_Card';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Edit_Pocket_Agency_Plan } from './partials/Edit_Pocket_Agency_Plan';

const SubscriberInfo = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding-bottom: 1rem;
`
const SubscriberInfoUser = styled.div`
  border-radius: var(--smallRadius);
  background: ${p=>p.theme.mid};
  padding: var(--smallRadius);
  
`
export default function STRIPE_POCKET_AGENCY() {

    const [invoices, setInvoices] = useState([]) 
    const params = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [subscriber, setSubscriber] = useState('')
    const [editPocketAgency, setEditPocketAgency] = useState(false)
    
    const { alerts, setAlerts } = useContext(AlertContext);
    
      const getInvoices = (payload)=>{
          get_invoices(payload)
          .then(res =>{
            console.log('res.data', res);
            if(res?.data?.data?.code === "P4005")  
              setAlerts([...alerts,{message: {text: t('Invoices not found'), status: 'Loading',}, },]);
            else setInvoices(res?.data?.data)     
          }
      )}
      
      const fetchSubscriberInfo = async() => {
  
        get_subscriber_info({userId: params?.id}).then(async(res) => {
  
          console.log('RESSS get_subscriber_info', res);
          
           setSubscriber({...subscriber, subs: res?.data?.subs, info: res?.data?.user});
  
            let customer = res?.data?.subs[0]?.cusId;
            let subIds = res?.data?.subs?.map(sub => sub.subId);
            console.log('subIds', subIds);
           
        
          })  
          
      };
  
      useEffect(() => {
  
        let payload = {
        userId:params?.id,
        category: 'bond',
        customerId: params?.cusId,
        // fakeInvoice: false
        };
  
        getInvoices(payload);
      }, []);


      useEffect(() => {
        fetchSubscriberInfo();
      }, []);
      console.log('stripe subscriber', subscriber);
      

  return (
    <PageSixty>
   
      <PageAlwaysVisible>
      {/* {editPocketAgency ? 
        <Edit_Pocket_Agency_Plan
          // fakeSub = {fakeSub}
          editPocketAgency={editPocketAgency}
          setEditPocketAgency={setEditPocketAgency}
          fetchSubscriberInfo={fetchSubscriberInfo}
          userId={params.id}
        />
      : null}  */}
        <PageTitle>
            <Grey_Link iconLeft='Back' noPadding onClick={()=>navigate(-1)}/>
            <h1>Stripe</h1>
        </PageTitle>
        <SubscriberInfo>
          <SubscriberInfoUser>
            <h4>{subscriber?.info?.firstname} {subscriber?.info?.lastname}</h4>
            <p>{subscriber?.info?.username} </p>
          </SubscriberInfoUser>
          {/* <Main_Plain iconLeft='Settings' text={t('Subscription')} noPadding onClick={()=>setEditPocketAgency(true)}/> */}
        </SubscriberInfo>
        {invoices?.map((invoice, i) => (
          <Invoice_Card
            amountPaid={invoice?.amountPaid /100}
          >
            <p>{t("Period")}:</p>
            <h5>{new Date(invoice?.start * 1000).toLocaleDateString('el-GR')} - {new Date(invoice?.end * 1000).toLocaleDateString('el-GR')}</h5>
            {invoice?.status ? 
              <>
                <p>{t("Status")}:</p>
                <h5>{invoice?.status}</h5>
              </>
            :null}
          </Invoice_Card>
        ))}
      </PageAlwaysVisible>
    </PageSixty>
  )
}
