import axios from "axios"
import { config } from "../../config"


const toggleSubscription = (subscriptionId, action) => {
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/subscriptions/toggle_subscritpion`, {
            data: {
                subscriptionId: subscriptionId, 
                action: action
            }
        })
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const cancelSubscription = (subscriptionId) => {
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/subscriptions/cancel_subscription`, {
                subscriptionId: subscriptionId, 
        })
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const renewSubscription = (subscriptionId) => {
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/subscriptions/undo_cancel_subscription`, {
            subscriptionId: subscriptionId, 
        })
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}



const fetch_cancel_at_period_end = (subscriptionId) => {
    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/subscriptions/fetch_cancel_at_period_end?subscriptionId=${subscriptionId}`)
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const create_customer = (customer, email, userId, category) =>{
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/create_customer`,{
            customer: customer,
            email: email,
            userId: userId,
            category
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const get_customer = (userId, category) =>{
    return new Promise((resolve, reject) => {
        axios.get(`${config.GRIPHOOK_BACKEND_URL}/cart/get_customer?userId=${userId}&category=${category}`)
        .then(res => {
            console.log('res',res?.data)
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}
const get_customer_with_email = (email,category) =>{
    return new Promise((resolve, reject) => {
        axios.get(`${config.GRIPHOOK_BACKEND_URL}/cart/get_customer?email=${email}&category=${category}`)
        .then(res => {
            console.log('res',res?.data)
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}
const calculate_tax = (customer,cart, coupon) =>{
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/calculate_tax`,{
            customer: customer,
            products: cart,
            coupon: coupon
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const calculate_tax_manual = (country, coupon, selectedAmount, isInvoice) =>{
    console.log("country", country)
    console.log("coupon", coupon)
    console.log("selectedAmount", selectedAmount)
    console.log("isInvoice", isInvoice)

    return new Promise((resolve, reject) => {
        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/manual_tax_calculation`,{
            country: country,
            coupon: coupon,
            amount: selectedAmount,
            isInvoice: isInvoice
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const create_confirm_payment_intent = (customer, cart, trial, userId, paymentMethodId, amount, currency) =>{
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/payment_intent?customer=${customer}`,{
            cart: cart,
            trial: trial,
            userId: userId,
            paymentMethodId: paymentMethodId,
            amount: amount,
            currency: currency
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const create_confirm_subscription = (customer, cart, trial, userId, email, paymentMethodId, quest, coupon, billing, amountPlusTax) =>{
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/trial_subscription?customer=${customer}`,{
            cart: cart,
            trial: trial,
            userId: userId,
            email: email, 
            paymentMethodId: paymentMethodId,
            quest: quest,
            coupon: coupon,
            billing: billing,
            amount:amountPlusTax
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const create_confirm_subscription_no_card = (customer, customerData, cart, trial, userId, email, paymentMethodId, quest, coupon,) =>{
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/trial_no_card_subscription`,{
            cart: cart,
            trial: trial,
            userId: userId,
            email: email, 
            paymentMethodId: paymentMethodId,
            quest: quest,
            coupon: coupon,
            customer: customerData,
            customerId: customer
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const coupon_validation = (coupon, category, customer) =>{
    return new Promise((resolve, reject) => {
        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/validate_coupon`,{
            coupon: coupon,
            customer: customer,
            category: category
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const connect_customer_with_userId = (customer, userId) =>{
    return new Promise((resolve, reject) => {
        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/connect_customer_with_userId`,{
            customerData: customer,
            userId: userId,
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}
const connect_subscription_with_customer = (customer, userId) => {
    return new Promise((resolve, reject) => {

        axios.post(`${config.ERISED_BACKEND_URL}/subscriptions/connect_sub_with_customer`, {
            registeredCustomer:customer,
            userId: userId
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const one_step_susbcription_creation = (customer, customerData, cart, trial, email, paymentMethodId, quest, coupon, category) =>{
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/one_step_subscripton`,{
            cart: cart,
            trial: trial,
            email: email, 
            paymentMethodId: paymentMethodId,
            quest: quest,
            coupon: coupon,
            customer: customerData,
            customerId: customer,
            category: category
        })
        .then(res => {
            console.log("RES ONE STEP SUBSCRIPTION", res)
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const get_total_revenue = (customer, timeRange, subId, liveData) =>{

    return new Promise((resolve, reject) => {

        let data = {customerId: customer};
        // if (timeRange) data.time_ranges = [timeRange];
        if (subId) data.subId = subId;
        if (liveData) data.liveData = liveData;

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/transactions/getTotalRevenue`, data)
        .then(res => {
            let revenue = res?.data;

            console.log("GET TOTAL REVENUE!", revenue)
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const get_user_total_revenue = (userId, timeRange) =>{

    return new Promise((resolve, reject) => {

        let data = {userId: userId};
        if (timeRange) data.time_ranges = [timeRange];

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/transactions/getTotalUserRevenue`, data)
        .then(res => {
            let revenue = res?.data;

            console.log("GET USER TOTAL REVENUE", revenue)
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const get_meta_adspend = (subIds, timeRange, fromMeta) =>{
    return new Promise((resolve, reject) => {

        let data = {quests: subIds};
        if (timeRange) data.time_ranges = [timeRange];
        if (fromMeta) data.fromMeta = fromMeta;

        console.error('FROM META', fromMeta)

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/transactions/getMetaAdspend`, data)
        .then(res => {
            let spend = res?.data?.spend?.metaAdspend?.data;

            if (spend?.length) spend = spend[0]?.spend;
            else spend = 0;

            console.log("GET META SPEND", spend)
            resolve(spend)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const get_meta_adspend_from_questad = (subIds, timeRange, fromMeta) =>{
    return new Promise((resolve, reject) => {

        let data = {quests: subIds};
        if (timeRange) data.time_ranges = [timeRange];
        if (fromMeta) data.fromMeta = fromMeta;

        axios.post(`${config.DAILYPROFIT_BACKEND_URL}/adminPanel/getMetaAdspend`, data)
        .then(res => {
            let spend = res?.data?.metaAdspend?.data;

            if (spend?.length) spend = spend[0]?.spend;
            else spend = 0;

            console.log("GET META SPEND", spend)
            resolve(spend)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const get_meta_adspend_all_time = (subIds, timeRange) =>{
    return new Promise((resolve, reject) => {

        let data = {quests: subIds};
        if (timeRange) data.time_ranges = [timeRange];

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/transactions/getMetaAdspendAllTime`, data)
        .then(res => {
            let spend = res?.data?.spend?.metaAdspend?.data;

            if (spend?.length) spend = spend[0]?.spend;
            else spend = 0;

            console.log("GET META SPEND", spend)
            resolve(spend)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

export {
    toggleSubscription,
    cancelSubscription,
    renewSubscription,
    fetch_cancel_at_period_end,
    create_customer,
    get_customer,
    calculate_tax,
    calculate_tax_manual,
    create_confirm_payment_intent,
    create_confirm_subscription,
    coupon_validation,
    create_confirm_subscription_no_card,
    get_customer_with_email,
    connect_customer_with_userId,
    connect_subscription_with_customer,
    one_step_susbcription_creation,
    get_total_revenue,
    get_meta_adspend,
    get_meta_adspend_all_time,
    get_user_total_revenue,
    get_meta_adspend_from_questad
};
