import { CardsFull, PageFull, PageTitle, Unlock_Card, logo, ThemeContext, Main_Loader, PageAlwaysVisible, Section_Title, device, Focus_Plain, Card, PageDesktopOnly, PageSixty, CardsSixty, Main_Text_Loader } from 'monica-alexandria'
import React, {useEffect, useState, useContext} from 'react'
import styled from 'styled-components'
import My_Plans_Lois from './components/My_Plans_Lois'
import { useTranslation } from 'react-i18next'
import My_Plans_Dailyprofit from './components/My_Plans_Dailyprofit'
import My_Plans_PocketAgency from './components/My_Plans_Pocket_Agency'
import { get_my_subscriptions } from '../../services/api/Mocks'
import store from '../../redux/store'
import i18n from '../../localization/i18next'
import { useNavigate } from 'react-router-dom'
import { create_plan_message } from '../../helpers/prettifyFunctions'
import { connect_customer_with_userId, connect_subscription_with_customer, get_customer_with_email } from '../../services/api/Subscriptions'

const currencies = require('../../data/currencies.json')

const clients = require('../../config/index').config;

const MyPlans = styled(PageSixty)`

`

const MyPlansGrid = styled(CardsSixty)`
    align-items: auto;
`
// const MoreToolsGrid = styled(CardsFull)`

// ` 
const MoreTools = styled(Card)`
    padding: 2rem;
    border-radius: .5rem;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: box-shadow .5s;
    
    &:hover{
        box-shadow: ${(p)=> p.theme.outFocus};
        transition: box-shadow .5s;
    }
`
 
const MoreToolsTop = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 2rem;
 
    img{
        width: 12rem;
    }
 
    h6{
        margin-bottom: 2rem;
    }
 
    @media ${device.lg} {
        grid-template-columns: 1fr;
    }
 
    @media ${device.md} {
        grid-template-columns: auto 1fr;
    }
 
    @media ${device.sm} {
        grid-template-columns: 1fr;
    }
` 

const MoreToolsActions = styled.div`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid ${p=>p.theme.low};
    padding-top: 2rem;
`
 
export default function MY_PLANS() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const userId = store.getState()?.user?.user?.sub;
    const email = store.getState()?.user?.user?.username;

    const lang = i18n.language ? i18n.language : 'en';
    const {theme} = useContext(ThemeContext);
    const [loading, setLoading] = useState(true);

    const [mySubs, setMySubs] = useState([]);
    const [loisActivePlan, setLoisActivePlan] = useState(null);
    const [pocketActivePlan, setPocketActivePlan] = useState(null);
    const [activeQuests, setActiveQuests] = useState([]);    
    const [inactiveQuests, setInactiveQuests] = useState([]);

    const check_for_unlinked_customer = () =>{
        get_customer_with_email(email,'bond')
        .then((res) =>{                    
            console.log("data get_customer_with_email", res)

            if(res?.customer && !res?.customer?.category) {
                connect_customer_with_userId(res?.customer, userId)
                .then((res_cus) =>{ 
                    connect_subscription_with_customer(res_cus?.customer,userId)
                    .then((res_sub) =>{
                        console.log("data connect_subscription_with_customer", res_sub)
                        get_my_subscriptions(userId)
                        .then(data => {
                            const pocket_sub = data.data?.find(sub => sub?.category === "bond")
                            const pocketDisplayedPlan = pocket_sub?.tools?.find(plan => plan?.active) // 
                            if((pocketDisplayedPlan)) setPocketActivePlan(pocketDisplayedPlan) // if pocket is active display the active subscription
                            else setPocketActivePlan(pocket_sub?.tools[pocket_sub?.tools?.length - 1]) // else display the latest canceled subscription 
                            setLoading(false)

                        })
                        .catch(err => console.log(err))
                    })
                    .catch(err => console.log(err))
                    
                })
                .catch(err => console.log(err))
                setLoading(false);

            }
            setLoading(false);

        })
        .catch(err => console.log(err))
    }

    useEffect(() => {
        get_my_subscriptions(userId)
        .then(res => {
            console.log('my subs: ', res);
            setMySubs(res.data)
            const loisSub = res.data?.find(sub => sub?.category === "lois")
            const dailySub = res.data?.find(sub => sub?.category === "dailyprofit")
            const pocketSub = res.data?.find(sub => sub?.category === "bond")
            console.log('my pocketSub: ', pocketSub);
            console.log('my dailySub: ', dailySub);

            if(!pocketSub) check_for_unlinked_customer();

            const loisDisplayedPlan = loisSub?.tools?.find(plan => plan?.active)
            if((loisActivePlan)) setLoisActivePlan(loisDisplayedPlan) // if adsnap is active display the active subscription
            else setLoisActivePlan(loisSub?.tools[loisSub?.tools?.length - 1]) // else display the latest canceled subscription 

            const pocketDisplayedPlan = pocketSub?.tools?.find(plan => plan?.active) // 
            if((pocketDisplayedPlan)) setPocketActivePlan(pocketDisplayedPlan) // if pocket is active display the active subscription
            else setPocketActivePlan(pocketSub?.tools[pocketSub?.tools?.length - 1]) // else display the latest canceled subscription 

            setActiveQuests(dailySub?.tools?.filter(item => item?.active))
            setInactiveQuests(dailySub?.tools?.filter(item => !item?.active))
            setLoading(false)
         })
        .catch(err => console.log("error", err))
    }, [])

    console.log('loisActivePlan: ', loisActivePlan);
    console.log('mysubs:', mySubs);
    console.log('activeQuests:', activeQuests);
    console.log('inactiveQuests:', inactiveQuests);

  return (
    <MyPlans>
        <PageAlwaysVisible>
            <PageTitle>
                <h1>{t("My subscriptions")}</h1>
            </PageTitle>
            {loading ? 
                <Main_Loader /> 
            : 
                <MyPlansGrid>
                    {loisActivePlan ? 
                        <My_Plans_Lois 
                            tool={loisActivePlan?.name}
                            plan={t("Price breakdown")}
                            amount={currencies[loisActivePlan?.currency?.toUpperCase()]?.symbol + " " + (loisActivePlan?.amount / 100)?.toFixed(2)}
                            interval={create_plan_message(loisActivePlan?.taxBehavior, loisActivePlan?.interval, loisActivePlan?.interval_count,t)}
                            status={loisActivePlan?.status}
                            onClick={() => navigate(`/${lang}/plan/adsnap`)}
                            active={loisActivePlan?.active}
                        />
                        : null
                    }
                    {pocketActivePlan && 
                        <My_Plans_PocketAgency 
                            tool="Pocket Agency"
                            amount={currencies[pocketActivePlan?.currency?.toUpperCase()]?.symbol + " " + (pocketActivePlan?.amount / 100)?.toFixed(2)}
                            interval={create_plan_message(pocketActivePlan?.taxBehavior, pocketActivePlan?.interval, pocketActivePlan?.interval_count,t)}
                            status={pocketActivePlan?.status}
                            onClick={() => navigate(`/${lang}/plan/pocket-agency `)}
                            active={pocketActivePlan?.active}
                        />
                    }
                    {(activeQuests?.length > 0 || inactiveQuests?.length > 0) ? 
                        <My_Plans_Dailyprofit 
                            tool={"Questad"}
                            text={activeQuests?.length > 0 ? t("Active Quest(s)") : t("Inactive Quest(s)")}
                            active={activeQuests?.length > 0 ? true : false}
                            numOfQuests={activeQuests?.length > 0 ? activeQuests?.length : inactiveQuests?.length}
                            onClick={() => navigate(`/${lang}/plan/questad`)}
                        />
                        :
                        null
                    }
                </MyPlansGrid>
            } 
        </PageAlwaysVisible>
        <PageDesktopOnly>
            {!loisActivePlan && !(activeQuests?.length > 0 || inactiveQuests?.length > 0) ? (
                <Section_Title text={t("More tools")} />
            ) : null}    
                            
            {!(activeQuests?.length > 0 || inactiveQuests?.length > 0) ?
                <MoreTools onClick={() => window.location.assign(`${clients['dailyprofit']}/${lang}`)} >
                    <MoreToolsTop>
                        <img src={logo.dailyprofit[theme]} />
                        <main>
                            <h3>Questad</h3>
                            <h6>{t("Automated social media Ads tool")}</h6>
                            <p>{t("Streamline your advertising with our automated social media ads tool, designed to craft complex campaigns and strategies from simple questions. Perfect for freelancers and small businesses with tight budgets, it helps you reach potential leads effortlessly. Say goodbye to daunting marketing demands and hello to effective, budget-friendly promotions.")}</p>
                        </main>
                    </MoreToolsTop>
                    <MoreToolsActions>
                        <Focus_Plain noPadding text={t("Learn more")} onClick={() => window.location.assign(`${clients['dailyprofit']}/${lang}`)} iconRight="Next" />
                    </MoreToolsActions>
                </MoreTools>
                :
                null
            }

            {!loisActivePlan ?
                <MoreTools onClick={() => window.location.assign(`${clients['lois']}/${lang}`)} >
                    <MoreToolsTop>
                        <img src={logo.lois[theme]} />
                        <main>
                            <h3>Adsnap</h3>
                            <h6>{t("Meta reporting tool")}</h6>
                            <p>{t("Enhance your client presentations with our digital marketing tool, designed for showcasing Facebook and Instagram campaign results. Craft beautiful, detailed reports with ease, using dynamic designs and KPIs that ensure your work stands out. Ideal for digital marketers that want to show compelling evidence of their success.")}</p>
                        </main>
                    </MoreToolsTop>
                    <MoreToolsActions>
                        <Focus_Plain noPadding text={t("Learn more")} onClick={() => window.location.assign(`${clients['lois']}/${lang}`)} iconRight="Next" />
                    </MoreToolsActions>
                </MoreTools>
                :
                null
            }
        </PageDesktopOnly>
    </MyPlans>
  )
}
